<template>
  <CommonFocusWrapper>
    <a-card title="Anmelden oder Konto erstellen">
      <a-form ref="loginForm" :model="form" :rules="rules" @finish="login" hide-required-mark
              layout="vertical">
        <a-form-item name="email" label="E-Mail-Adresse" :colon="false">
          <a-input
              type="email"
              name="email"
              size="large"
              v-model:value="form.email"
          >
          </a-input>
        </a-form-item>
        <a-form-item name="password" label="Passwort" :colon="false">
          <a-input-password
              name="password"
              size="large"
              v-model:value="form.password"
          >
          </a-input-password>
          <nuxt-link to="/auth/forgot-password" :class="$style.passwordForgot">Passwort vergessen?</nuxt-link>
        </a-form-item>

        <a-alert v-if="error" type="error" :message="error" showIcon/>

        <a-form-item name="keepMeLoggedIn" style="margin-bottom: 10px">
          <a-checkbox v-model:checked="form.keepMeLoggedIn">Angemeldet bleiben</a-checkbox>
        </a-form-item>

        <a-button
            type="primary"
            html-type="submit"
            :loading="loading"
            size="large"
            block
            style="margin-top: 10px"
        >Anmelden
        </a-button>

        <CommonEBayLogin other/>

        <div :class="$style.links">
          <span>Sie haben noch kein Konto?</span><br>
          <nuxt-link to="/auth/register">Konto erstellen</nuxt-link>
        </div>

      </a-form>
    </a-card>

  </CommonFocusWrapper>
</template>

<script setup lang="ts">
import {COOKIE_AUTH_TOKEN_STAGING, email, ErrorCodes, password} from '@shared';

definePageMeta({
  layout: 'centered',
  auth: {
    public: true
  },
  middleware: defineNuxtRouteMiddleware((to, from) => {
    const rootStore = useRootStore();
    if (rootStore.user) {
      return navigateTo('/');
    }
  }),
});

const rules = {
  email,
  password
};

const loginForm = ref();
const loading = ref(false);
const error = ref<string | null>(null);
const form = reactive({
  email: '',
  password: '',
  keepMeLoggedIn: true
});

const {$log} = useNuxtApp();
const config = useRuntimeConfig();
const accessTokenCookie = useCookie(COOKIE_AUTH_TOKEN_STAGING, {
  path: '/',
  maxAge: 60 * 60 * 2 // 2h
});

const route = useRoute();
const authStore = useAuthStore();

async function login() {
  loading.value = true;
  error.value = null;

  try {
    await authStore.login(toRaw(form));
    if (config.public.stage === 'dev-staging' && authStore.accessToken) {
      accessTokenCookie.value = authStore.accessToken;
    }

    const redirectTo = decodeURIComponent(typeof route.query.r === 'string' ? route.query.r : '');

    if (redirectTo) {
      await navigateTo(redirectTo);
    } else {
      await navigateTo('/');
    }
  } catch (e: any) {
    error.value = 'Etwas ist schief gelaufen...';
    if (!e.data?.code) {
      const {capture} = useLog();
      capture(e);
      return;
    }

    switch (e.data.code) {
      case ErrorCodes.LOGIN_FAILED:
        error.value = 'Login fehlgeschlagen.';
        return;
      case ErrorCodes.USER_IS_REGISTERING:
        await navigateTo({
          path: '/confirm/registration',
          query: {
            resend: form.email
          }
        });
        return;
      default:
        $log.error(e);
    }
  } finally {
    loading.value = false;
  }
}
</script>

<style lang="scss" module>
.links {
  margin-top: 40px;
  text-align: center;
}

.passwordForgot {
  position: absolute;
  right: 0;
  top: -50px;
  white-space: nowrap;
}
</style>